import { Fragment, useEffect, useMemo, useState } from 'react';
import dynamic from 'next/dynamic';
import { LoadingComponent } from '../src/components/atom/Loading';
import useAuth from '../src/features/auth/useAuth';
import useRouterHook from '@/hooks/useRouterHook.hook';
import { Cip } from '@/utils/cip.utils';

const GameSearchComponent = dynamic(
	() => import('../src/components/molecules/GameSearchComponent'),
	{ ssr: false, loading: () => <LoadingComponent /> }
);
const GameCategoryComponent = dynamic(
	() => import('../src/components/molecules/GameCategoryComponent'),
	{ ssr: false, loading: () => <LoadingComponent /> }
);
const GameLotteryResultComponent = dynamic(
	() => import('../src/components/molecules/GameLotteryResultComponent'),
	{ ssr: false, loading: () => <LoadingComponent /> }
);
const AnnouncementComponent = dynamic(
	() => import('../src/components/molecules/AnnouncementComponent'),
	{ ssr: false, loading: () => <LoadingComponent /> }
);
const AuthSectionComponent = dynamic(
	() => import('../src/components/molecules/AuthSectionComponent'),
	{ ssr: false, loading: () => <LoadingComponent /> }
);
const JackpotProgressComponent = dynamic(
	() => import('../src/components/molecules/JackpotProgressComponent'),
	{ ssr: false, loading: () => <LoadingComponent /> }
);
const RealtimeTransactionComponent = dynamic(
	() => import('../src/components/molecules/RealtimeTransactionComponent'),
	{ ssr: false, loading: () => <LoadingComponent /> }
);
const GamepopularComponent2 = dynamic(
	() => import('../src/components/molecules/GamepopularComponent2'),
	{ ssr: false, loading: () => <LoadingComponent /> }
);
const GameRecommendedComponent2 = dynamic(
	() => import('../src/components/molecules/GameRecommendedComponent2'),
	{ ssr: false, loading: () => <LoadingComponent /> }
);
const GameProviderComponent2 = dynamic(
	() => import('../src/components/molecules/GameProviderComponent2'),
	{ ssr: false, loading: () => <LoadingComponent /> }
);
const RedirectGameToto = dynamic(
	() =>
		import('../src/components/molecules/RedirectGame/index').then(
			(module) => module.RedirectGameToto
		),
	{
		ssr: false,
		loading: () => <LoadingComponent />
	}
);

const Home = () => {
	const { router } = useRouterHook();
	const { isLoggedIn, loginTransferToken } = useAuth();
	const [brandSectionOrder] = useState([
		'announcement',
		'authSection',
		'gamecategory',
		'toto',
		// 'jackpotprogress'
		'gamesearch',
		'gameprovider',
		// 'gamelotteryresult',
		'gamePopular',
		'gamerecommended',
		'realtimetransaction'
	]);

	const searchParams = new URLSearchParams();
	searchParams.append('data', router.query.data);
	const enc_data = searchParams.toString().split('data=')[1];
	useEffect(() => {
		const fetchData = async () => {
			try {
				if (enc_data && enc_data !== 'undefined') {
					const transferData = await Cip.decryptData(enc_data);
					const { token } = transferData;
					loginTransferToken(token);
				}
			} catch (error) {
				console.error('Error decrypting data:', error);
			} finally {
				const { pathname, query } = router;

				if (enc_data && enc_data !== 'undefined') {
					delete query.data;

					// Update the URL without the query parameter
					router.replace(
						{
							pathname,
							query
						},
						undefined,
						{ shallow: true }
					);
				}
			}
		};

		fetchData();
	}, [enc_data]);

	const sectionOrderComponent = useMemo(() => {
		return brandSectionOrder.map((item, index) => {
			const sectionSlideMode = index % 2 === 0 ? 'fromLeft' : 'fromRight';
			let component;
			switch (item) {
				// case 'banner':
				// 	component = <BannerMain slideMode={sectionSlideMode} />;
				// 	break;
				case 'authSection':
					if (!isLoggedIn) component = <AuthSectionComponent className="mb-section" />;
					break;
				case 'announcement':
					component = <AnnouncementComponent />;
					break;
				case 'gamecategory':
					component = <GameCategoryComponent />;
					break;
				case 'gamesearch':
					component = (
						<div className="hidden md:block">
							<GameSearchComponent slideMode={sectionSlideMode} />
						</div>
					);
					break;
				case 'gameprovider':
					component = <GameProviderComponent2 />;
					break;
				case 'gamelotteryresult':
					component = <GameLotteryResultComponent slideMode={sectionSlideMode} showTitle />;
					break;
				case 'gamerecommended':
					component = <GameRecommendedComponent2 />;
					break;
				case 'gamePopular':
					component = <GamepopularComponent2 />;
					break;
				case 'realtimetransaction':
					component = <RealtimeTransactionComponent />;
					break;
				case 'jackpotprogress':
					component = <JackpotProgressComponent />;
					break;
				case 'toto':
					component = <RedirectGameToto />;
					break;
				default:
					component = null;
					break;
			}
			return component ? <Fragment key={index}>{component}</Fragment> : null;
		});
	}, [isLoggedIn]);
	return <>{sectionOrderComponent}</>;
};

export default Home;
