export const Cip = (function () {
	// Private function to encrypt data using XOR and a secret key
	function xorEncrypt(data, key) {
		let result = '';
		for (let i = 0; i < data.length; i++) {
			result += String.fromCharCode(data.charCodeAt(i) ^ key.charCodeAt(i % key.length));
		}
		return result;
	}

	// Private function to decrypt data using XOR and a secret key
	function xorDecrypt(data, key) {
		let result = '';
		for (let i = 0; i < data.length; i++) {
			result += String.fromCharCode(data.charCodeAt(i) ^ key.charCodeAt(i % key.length));
		}
		return result;
	}

	// Private function to Base64 encode data
	function base64Encode(data) {
		return btoa(data);
	}

	// Private function to Base64 decode data
	function base64Decode(data) {
		return atob(decodeURIComponent(data));
	}

	// Private function to hash a string
	function simpleHash(str) {
		let hash = 0,
			i,
			chr;
		if (str.length === 0) return hash;
		for (i = 0; i < str.length; i++) {
			chr = str.charCodeAt(i);
			hash = (hash << 5) - hash + chr;
			hash |= 0; // Convert to 32bit integer
		}
		return hash.toString(16);
	}

	// Function to generate a unique footprint from the browser
	function getFootprint() {
		const userAgent = navigator.userAgent;
		const screenResolution = `${screen.width}x${screen.height}`;
		const language = navigator.language || navigator.userLanguage;
		const timezoneOffset = new Date().getTimezoneOffset();
		const platform = navigator.platform || 'unknown';
		const hardwareConcurrency = navigator.hardwareConcurrency || 'unknown';
		const colorDepth = screen.colorDepth || 'unknown';

		const footprint = `${userAgent}${screenResolution}${language}${timezoneOffset}${platform}${hardwareConcurrency}${colorDepth}`;
		return simpleHash(footprint);
	}

	// Public API
	return {
		createUrl: function (baseUrl, data) {
			try {
				const secretKey = 'kVvT0gx9XRj7iwxXyjSa3wSJswDXonD6';
				const dataStr = JSON.stringify(data);
				const footprint = getFootprint();
				const combinedKey = secretKey + footprint;
				const encData = base64Encode(xorEncrypt(dataStr, combinedKey));

				const url = new URL(baseUrl);
				url.searchParams.append('data', encData);

				return url.toString();
			} catch (error) {
				console.error('Error creating secure URL:', error);
				return null;
			}
		},

		decryptData: function (encData) {
			try {
				const secretKey = 'kVvT0gx9XRj7iwxXyjSa3wSJswDXonD6';
				const footprint = getFootprint();
				const combinedKey = secretKey + footprint;
				const decodedData = base64Decode(encData);
				return JSON.parse(xorDecrypt(decodedData, combinedKey));
			} catch (error) {
				console.error('Error decrypting data:', error);
				return null;
			}
		}
	};
})();
